import * as React from 'react';
import moment from 'moment';
import { Screenshot } from './Screenshot';
import { IProject } from '../../interfaces/Project';

interface ProjectProps {
  project: IProject;
}

export const Project = (props: ProjectProps) => {
  const { project } = props;
  const {
    type,
    id,
    image,
    title,
    description,
    tags,
    screenshots,
    when,
    client,
    siteLink
  } = project;

  return (
    <div className={`pf-grid-item ${type}`}>
      <a className="pf-project" href={`#${id}`}>
        <figure className="pf-figure">
          <img src={`/images/cms/portfolio/${image}`} alt={title} />
        </figure>

        <div className="pf-caption text-center">
          <div className="valign-table">
            <div className="valign-cell">
              <h2 className="pf-title text-upper">{title}</h2>

              <div className="pf-text clear-mrg">
                <p>{tags.join(', ')}</p>
              </div>

              <button className="pf-btn btn btn-primary">View More</button>
            </div>
          </div>
        </div>
      </a>

      <div id={id} className="pf-popup clearfix">
        <div className="pf-popup-col1">
          <div className="pf-popup-media cr-slider" data-init="none">
            {!!screenshots.length &&
              screenshots.map((screenshot, index) => (
                <Screenshot key={index} screenshot={screenshot} title={title} />
              ))}
          </div>
        </div>

        <div className="pf-popup-col2">
          <div className="pf-popup-info">
            <h2 className="pf-popup-title text-upper">{title}</h2>

            <div className="text-muted">
              <strong>{tags.join(', ')}</strong>
            </div>

            <dl className="dl-horizontal">
              <dt>When:</dt>
              <dd>{when}</dd>
              {!!siteLink && (
                <span>
                  <dt>Link:</dt>
                  <dd>
                    <a href={siteLink.href} target="_blank">
                      {siteLink.label}
                    </a>
                  </dd>
                </span>
              )}

              <dt>Client:</dt>
              <dd>{client}</dd>
            </dl>

            <div className="more-container">
              {Array.isArray(description) ? (
                description.map((entry, index) => <p key={index}>{entry}</p>)
              ) : (
                <p>{description}</p>
              )}
              <a href="#" className="more-btn">
                more...
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
