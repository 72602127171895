import * as React from 'react';
import { IReference } from '../../interfaces/Reference';

interface ReferenceProps {
  reference: IReference;
}

export const Reference = ({ reference }) => {
  const { avatar, name, date, company, title, content } = reference;

  return (
    <div className="ref-box brd-btm hreview">
      <div className="ref-avatar">
        <img
          alt=""
          src={`/images/people/${avatar}`}
          srcSet={`/images/people/${avatar} 2x`}
          className="avatar avatar-54 photo"
          height="54"
          width="54"
        />
      </div>

      <div className="ref-info">
        <div className="ref-author">
          <strong>{name}</strong>
          <span>
            {date}, {company}, {title}
          </span>
        </div>

        <blockquote className="ref-cont clear-mrg">
          <p>{content}</p>
        </blockquote>
      </div>
    </div>
  );
};
