import * as React from 'react';

export const Profile = () => {
  return (
    <section className="section brd-btm padd-box">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="title-lg text-upper">Profile</h2>

          <div className="text-box">
            <p>
              Extensively experienced in end to end FE development process,
              including FE architecture design, coding, testing, builds and
              CI/CD pipeline deployments. Passionate about adapting software
              engineering principles into the development of Front End web
              applications, coding standards, best practices and design
              patterns.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-sm-9">
          <div className="crt-share-box clearfix">
            <button id="btn-share" className="btn btn-share btn-upper">
              <span className="crt-icon crt-icon-share-alt"></span>
              Share
            </button>
            <script
              type="text/javascript"
              src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5edd4619199c0328"
            ></script>
            <div className="addthis_inline_share_toolbox"></div>
          </div>
        </div>
        <div className="col-sm-3 text-right"></div>
      </div> */}
    </section>
  );
};
