import React, { useState } from 'react';

import { IJob } from '../../interfaces/Job';
import moment from 'moment';
import { scrollToId } from '../../utils/scroll-to-id';
import styles from './experience.module.scss';

interface JobProps {
  job: IJob;
}

export const Job = (props: JobProps) => {
  const { job, index } = props;
  const { start, end, position, logo, company, responsibilities } = job;
  const startYear = moment(start).format('YYYY');
  const startMonth = moment(start).format('MMM');
  const endYear = end ? moment(end).format('YYYY') : '';
  const endMonth = end ? moment(end).format('MMM') : 'now';
  const [isMore, setMore] = useState(false);
  const responsibilitiesFiltered =
    index === 0 || isMore ? responsibilities : [responsibilities[0]];

  function toggleMore() {
    scrollToId(job.id);
    setMore(!isMore);
  }

  return (
    <div className="education-box" id={job.id}>
      {!!logo && (
        <div className="floating-logo">
          <figure>
            <img
              src={`/images/logos/${logo}`}
              alt={`${position} at ${company}`}
            />
          </figure>
        </div>
      )}
      <time className="education-date" dateTime={start}>
        <span>
          {startMonth} <strong className="text-upper">{startYear}</strong> -{' '}
          {`${endMonth} `}
          {end && <strong className="text-upper">{endYear}</strong>}
        </span>
      </time>
      <h3>{position}</h3>
      <span className="education-company">{company}</span>
      <ul className={styles.responsibilitesList}>
        {responsibilitiesFiltered.map((responsibility, index) => (
          <li key={index}>{responsibility}</li>
        ))}
      </ul>

      {index !== 0 && (
        <div className={`${styles.responsibilitiesFilter} pf-filter clear-mrg`}>
          {!isMore && (
            <button className="active" onClick={toggleMore}>
              see more
            </button>
          )}
          {!!isMore && (
            <button className="active" onClick={toggleMore}>
              see less
            </button>
          )}
        </div>
      )}
    </div>
  );
};
