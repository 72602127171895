import * as React from 'react';
import { Job } from './Job';
import { jobs } from '../../data/jobs';

export const Experience = () => {
  return (
    <div className="crt-paper clearfix">
      <div className="crt-paper-cont paper-padd clear-mrg">
        <section className="section padd-box">
          <h2 className="title-lg text-upper">work experience</h2>
          <div className="education">
            {jobs.map((job, index) => (
              <Job key={index} index={index} job={job} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};
