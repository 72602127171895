import * as React from 'react';
import { IClient } from '../../interfaces/Client';

interface ClientProps {
  client: IClient;
}

export const Client = ({ client }) => {
  const { name, logo } = client;

  return (
    <figure>
      <img src={logo} srcSet={`${logo} 2x`} alt={name} />
    </figure>
  );
};
