import * as React from 'react';
import moment from 'moment';
import { IScreenshot } from '../../interfaces/Screenshot';

interface ScreenshotProps {
  screenshot: IScreenshot;
}

export const Screenshot = (props: ScreenshotProps) => {
  const { screenshot, title } = props;
  const { img, alt } = screenshot;

  return (
    <div className="pf-popup-embed">
      <img src={`/images/cms/portfolio/${img}`} alt={alt || title} />
    </div>
  );
};
