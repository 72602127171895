import React, { useState } from 'react';
import { Clients } from './Clients';
import { Reference } from './Reference';
import { references } from '../../data/references';
import styles from './references.module.scss';
import { scrollToId } from '../../utils/scroll-to-id';

export const References = () => {
  const [isAll, setAll] = useState(false);
  let referencesFiltered = isAll
    ? references
    : references.filter((ref, index) => {
        return index < 3;
      });

  function enableAll() {
    setAll(true);
  }

  function disableAll() {
    setAll(false);
    scrollToId('references-list');
  }

  return (
    <div className="crt-paper clearfix">
      <div className="crt-paper-cont paper-padd clear-mrg">
        <section className="section padd-box">
          <h2 className="title-lg text-upper">References</h2>
          <div className="padd-box-sm clear-mrg" id="references-list">
            {referencesFiltered.map((reference, index) => (
              <Reference key={index} reference={reference} />
            ))}
          </div>
          <div className={`pf-filter clear-mrg ${styles.amountLinks}`}>
            {!isAll && (
              <button className="active" onClick={enableAll}>
                see more
              </button>
            )}
            {isAll && (
              <button className="active" onClick={disableAll}>
                see recent
              </button>
            )}
          </div>
        </section>

        <Clients />
      </div>
    </div>
  );
};
