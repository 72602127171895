import * as React from 'react';
import { Languages } from './Languages';

export const Personal = () => {
  return (
    <section className="section padd-box">
      <div className="row">
        <div className="col-sm-6 clear-mrg">
          <h2 className="title-thin text-muted">personal information</h2>

          <dl className="dl-horizontal clear-mrg">
            <dt className="text-upper">name</dt>
            <dd>Tomasz Libich</dd>

            <dt className="text-upper">whereabouts</dt>
            <dd>London, UK</dd>

            <dt className="text-upper">website</dt>
            <dd>www.tomaszlibich.com</dd>

            <dt className="text-upper">freelance</dt>
            <dd>Available</dd>
          </dl>
        </div>

        <Languages />
      </div>
    </section>
  );
};
