import * as React from 'react';
import { Language } from './Language';
import { ILanguage } from '../../interfaces/Language';
import { languages } from '../../data/languages';

interface LanguageProps {
  language: ILanguage;
}

export const Languages = () => {
  return (
    <div className="col-sm-6 clear-mrg">
      <h2 className="title-thin text-muted">languages</h2>

      {languages.map((language, index) => (
        <Language key={index} language={language} />
      ))}
    </div>
  );
};
