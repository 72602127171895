import React, { useState } from 'react';

import styles from './contact.module.scss';
import { validateEmail } from '../../utils/validate-email';

export const Contact = () => {
  const [errors, setErrors] = useState(null);
  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formSubject, setFormSubject] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);

  function resetForm() {
    if (errors) {
      setErrors(null);
    }

    if (formSubmitSuccess) {
      setFormSubmitSuccess(false);
    }

    if (formSubmitError) {
      setFormSubmitError(false);
    }
  }

  function updateName(event) {
    setFormName(event.target.value);
    resetForm();
  }

  function updateEmail(event) {
    setFormEmail(event.target.value);
    resetForm();
  }

  function updateSubject(event) {
    setFormSubject(event.target.value);
    resetForm();
  }

  function updateMessage(event) {
    setFormMessage(event.target.value);
    resetForm();
  }

  function isFormValid() {
    const errors = {};

    if (!formName) {
      errors.formName = true;
    }

    if (!formEmail || !validateEmail(formEmail)) {
      errors.formEmail = true;
    }

    if (!formSubject) {
      errors.formSubject = true;
    }

    if (!formMessage) {
      errors.formMessage = true;
    }

    if (
      errors.formName ||
      errors.formEmail ||
      errors.formSubject ||
      errors.formMessage
    ) {
      setErrors(errors);

      return false;
    }

    return true;
  }

  function submit() {
    // const { firebase } = window;

    // const promise = firebase
    //   .database()
    //   .ref('contact-form-messages')
    //   .push()
    //   .set({
    //     name: formName,
    //     email: formEmail,
    //     subject: formSubject,
    //     message: formMessage,
    //     timestamp: Date.now()
    //   })
    //   .then(() => {
    //     setFormSubmitSuccess(true);
    //   })
    //   .catch(() => {
    //     setFormSubmitError(true);
    //   });
    console.warn('The message has not been sent.');
    setFormSubmitError(true);
  }

  function onFormSubmit(event) {
    event.preventDefault();

    if (isFormValid()) {
      submit();
    }
  }

  return (
    <div className="crt-paper clearfix">
      <div className="crt-paper-cont paper-padd clear-mrg">
        <div className="padd-box">
          <div className="contact-upper-header">
            <h2 className="title-lg text-upper">contact me</h2>
            <ul className="crt-social">
                <li>
                  <a
                    href="http://www.linkedin.com/pub/tomasz-libich/19/1a5/838"
                    taget="_blank"
                  >
                    <span className="crt-icon crt-icon-linkedin"></span>
                  </a>
                </li>
                <li>
                  <a href="https://github.com/tomaszlibich" target="_blank">
                    <span className="crt-icon crt-icon-github"></span>
                  </a>
                </li>
                <li>
                  <a href="http://twitter.com/#!/tomaszlibich" target="_blank">
                    <span className="crt-icon crt-icon-twitter"></span>
                  </a>
                </li>
              </ul>
          </div>

          <div className="padd-box-xs">
            <header className="contact-head">
              <h3 className="title text-upper">get in touch</h3>
            </header>
          </div>

          <div className="padd-box-sm">
            <form action="#" method="post" className="contact-form">
              <div className="form-group">
                <label className="form-label" htmlFor="author">
                  Your Name
                </label>
                <div className="form-item-wrap">
                  <input
                    id="author"
                    className="form-item"
                    type="text"
                    required="required"
                    onChange={updateName}
                    value={formName}
                  />
                  {errors && errors.formName && (
                    <span className={styles.errorHint}>
                      Please provide your name.
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Your E-mail
                </label>
                <div className="form-item-wrap">
                  <input
                    id="email"
                    className="form-item"
                    type="email"
                    required="required"
                    placeholder="Enter a valid email address"
                    onChange={updateEmail}
                    value={formEmail}
                  />
                  {errors && errors.formEmail && (
                    <span className={styles.errorHint}>
                      A valid e-mail is required, so I can respond to your
                      message.
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="subject">
                  Subject
                </label>
                <div className="form-item-wrap">
                  <input
                    id="subject"
                    className="form-item"
                    type="text"
                    required="required"
                    onChange={updateSubject}
                    value={formSubject}
                  />
                  {errors && errors.formSubject && (
                    <span className={styles.errorHint}>
                      Please provide subject.
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="message">
                  Your Message
                </label>
                <div className="form-item-wrap">
                  <textarea
                    id="message"
                    className="form-item"
                    required="required"
                    onChange={updateMessage}
                    value={formMessage}
                  ></textarea>
                  {errors && errors.formMessage && (
                    <span className={styles.errorHint}>
                      Message is required.
                    </span>
                  )}
                </div>
              </div>

              {formSubmitSuccess && (
                <div className="form-result form-success">
                  Your message has been sent successfully. Thank you.
                </div>
              )}

              {formSubmitError && (
                <div className="form-result form-error">
                  I'm afraid something went wrong. Please try again later.
                </div>
              )}

              <div className="form-submit form-item-wrap">
                <button
                  id="form-submit-btn"
                  className="btn btn-primary btn-lg"
                  onClick={onFormSubmit}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          id="map"
          data-latitude="51.507351"
          data-longitude="-0.127758"
        ></div>
      </div>
    </div>
  );
};
