import * as React from 'react';
import { Profile } from './Profile';
import { Personal } from './Personal';

export const About = () => {
  return (
    <div className="crt-paper clearfix">
      <div className="crt-paper-cont paper-padd clear-mrg">
        <Profile />
        <Personal />
      </div>
    </div>
  );
};
