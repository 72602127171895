import * as React from 'react';
import { ILanguage } from '../../interfaces/Language';

interface LanguageProps {
  language: ILanguage;
}

export const Language = (props: LanguageProps) => {
  const { language } = props;

  if (!language) {
    return null;
  }

  const { progress } = language;
  const maxBullets = 10;

  const bullets = [];
  let bulletsCounter = maxBullets + 0;

  while (bulletsCounter) {
    bullets.unshift(
      <span
        key={bulletsCounter}
        className={`bullet ${bulletsCounter <= progress ? 'fill' : ''}`}
      ></span>
    );

    bulletsCounter -= 1;
  }

  return (
    <div
      className="progress-bullets crt-animate"
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={progress}
    >
      <strong className="progress-title">{language.title}</strong>
      <span className="progress-bar">{bullets.map(bullet => bullet)}</span>
      <span className="progress-text text-muted">{language.text}</span>
    </div>
  );
};
