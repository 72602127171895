import * as React from 'react';

import { Project } from './Project';

const projects = [
  {
    type: 'web_app',
    id: 'pf-popup-rbs',
    image: 'rbs1_1024.jpg',
    title: 'Online banking UI application',
    tags: [
      'Angular 12',
      'StencilJS',
      'Storybook',
      'Jest',
      'HTML5',
      'CSS2/CSS3',
      'SASS',
      'Bootstrap',
      'Material UI',
      'Webpack'
    ],
    screenshots: [
      {
        img: 'rbs1_big.jpg'
      },
      {
        img: 'rbs2_big.jpg'
      },
      {
        img: 'rbs3_big.jpg'
      },
      {
        img: 'rbs4_big.jpg'
      },
      {
        img: 'rbs5_big.jpg'
      },
      {
        img: 'rbs6_big.jpg'
      },
      {
        img: 'rbs2_big.jpg'
      },
      {
        img: 'rbs7_big.jpg'
      },
      {
        img: 'rbs8_big.jpg'
      },
      {
        img: 'rbs9_big.jpg'
      }
    ],
    when: 'Jan 2020 – Sep 2021',
    client: 'NatWest Group',
    description:
      'UI online banking aplication for NatWest Group customers. The project involved architecture design of re-platforming from legacy .NET stack and a solution that allowed to use the same code base that has been split between 6 different NatWest brands in the build process as well as interaction with existing backend REST infrastructure serving mobile applications. After release it was a public facing SPA that is currently being used by millions of customers on a daily basis.'
  },
  {
    type: 'web_app',
    id: 'pf-popup-db',
    image: 'dbpalace1_1024.jpg',
    title: 'Big Data driven Profit&Loss web app for bank managers',
    tags: [
      'AngularJS',
      'ReactJS',
      'Redux',
      'Jest',
      'Karma',
      'Mocha',
      'NodeJS',
      'Sass'
    ],
    screenshots: [
      {
        img: 'dbpalace1_big.jpg'
      },
      {
        img: 'dbpalace2_big.jpg'
      }
    ],
    when: 'June 2019 – Jan 2020',
    client: 'Deutsche Bank',
    description:
      'Re-platforming of a Big Data driven Profit&Loss web app for bank managers from AngularJS + Mocha / Karma + Protractor to ReactJS + Redux with Jest and Puppeteer. The application allows to generate financial analysis, profit&loss insights, forecasts, graphs, chars and financial reports.'
  },
  {
    type: 'web_app',
    id: 'pf-popup-gfk',
    image: 'gfk_1024.jpg',
    title: 'Big Data driven e-commerce ecosystem with business insights',
    tags: ['ReactJS', 'Redux', 'GraphQL', 'NodeJS', 'Sass', 'Typescript'],
    screenshots: [
      {
        img: 'gfk1_big.jpg'
      },
      {
        img: 'gfk2_big.jpg'
      },
      {
        img: 'gfk3_big.jpg'
      }
    ],
    when: 'January 2019 – June 2019',
    client: 'GfK UK',
    description:
      'Big Data based and developed with React / Redux / GraphQL e-commerce ecosystem application for business customers. The application allows to generate commercial analysis, product development insights, forecasts, graphs, chars and business reports.'
  },
  {
    type: 'web_app',
    id: 'pf-popup-telegraph',
    image: 'telegraph_1024.jpg',
    title:
      "The Telegraph's Newsroom Authoring web app for journalists and editors",
    tags: ['ReactJS', 'Redux', 'Jest', 'NodeJS', 'Sass'],
    screenshots: [
      {
        img: 'telegraph1_big.jpg'
      },
      {
        img: 'telegraph2_big.jpg'
      },
      {
        img: 'telegraph3_big.jpg'
      }
    ],
    when: 'July 2018 - January 2019',
    client: 'The Telegraph Media Group',
    description:
      "The Telegraph's Newsroom Authoring web app for journalists and editors. The application allows to create, edit and manage news, articles and galleries that eventually appear at telegraph.co.uk"
  },
  {
    type: 'web_app',
    id: 'pf-popup-aimia',
    image: 'aimia_1024.jpg',
    title: 'Big Data loyalty program manager',
    tags: [
      'AngularJS 1.5',
      'HTML5',
      'CSS2/CSS3 with Flexbox',
      'native Javascript',
      'NodeJS',
      'AJAX',
      'JSON/JSONP'
    ],
    screenshots: [
      {
        img: 'aimia1_big.png'
      },
      {
        img: 'aimia2_big.png'
      },
      {
        img: 'aimia3_big.png'
      }
    ],
    when: 'January 2017 – Nov 2017',
    client: 'Aimia Inc.',
    description:
      'Big Data based and developed with AngularJS 1.5 components e-commerce / loyalty program application for business customers. The application allows to generate commercial analysis, product development forecasts, graphs, chars and business reports.'
  },
  {
    type: 'web_app',
    id: 'pf-popup-ft-six-degrees',
    image: 'ft-six-degrees-1_big.jpg',
    title: 'Six Degrees',
    tags: [
      'ReactJS',
      'NodeJS',
      'HTML5',
      'CSS3',
      'Javascript',
      'D3',
      'Webpack',
      'CircleCI',
      'Heroku',
      'Docker'
    ],
    screenshots: [
      {
        img: 'ft-six-degrees-5.jpg'
      },
      {
        img: 'ft-six-degrees-6.jpg'
      },
      {
        img: 'ft-six-degrees-1_big.jpg'
      },
      {
        img: 'ft-six-degrees-2_big.jpg'
      },
      {
        img: 'ft-six-degrees-3_big.jpg'
      },
      {
        img: 'ft-six-degrees-4_big.jpg'
      }
    ],
    when: 'May 2016 – January 2017',
    siteLink: {
      href: 'http://www.ft.com',
      label: 'FT.com'
    },
    client: 'Financial Times',
    description: [
      "'Six degrees of separation' is the idea that all living things and everything else in the world are six or fewer steps away from each other so that a chain of 'a friend of a friend' statements can be made to connect any two people in a maximum of six steps. Based on that a new idea emerged within Financial Times to find out how noteworthy people are connected via news and features published by the company.",
      "Every published FT article is being described with structured metatags - it creates a huge Big Data source for analysis. During my time at the FT I had a pleasure to design an architecture and develop an e2e NodeJS, ReactJS and D3 based application called 'Six Degrees' that was making use of this data source and was a direct instantiation of the idea.",
      "The scope of the app was so called 'co-occurrence data': instances of two or more people being tagged in the same article. It wasn't clear what's the relationshop between people appearing in the same articles (do they have a positive, negative or neutral relationship), but as usual in the Big Data world, where amount of data is so significant, it was enough to create a high level of understanding of people's connections."
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-ft-parity-monitor',
    image: 'ft-parity-monitor-1_big.jpg',
    title: 'FT Internal Tools: KPI Tracking &amp; Parity Monitor',
    tags: [
      'ReactJS',
      'NodeJS',
      'HTML5',
      'CSS3',
      'Javascript',
      'D3',
      'Webpack',
      'CircleCI',
      'Heroku',
      'Docker'
    ],
    screenshots: [
      {
        img: 'ft-kpi-monitor.png'
      },
      {
        img: 'ft-parity-monitor-1_big.jpg'
      },
      {
        img: 'ft-parity-monitor-2_big.jpg'
      }
    ],
    when: 'May 2016 – January 2017',
    client: 'Financial Times',
    description: [
      "The migration from an old to new technology stack was a very challenging process for the back-end team I was working with during my time at Financial Times. The set of goals that they had to achieve was very complex and managing it was previously happening via... manager's spreadsheets. So even monitoring their progress by many other dependant teams wasn't an easy task.",
      "I've been asked to design and develop two boards that have been displayed at several screens within the organisation and were giving live updates about the progress of the team.",
      'FT Internal Tools: KPI Tracking &amp; Parity Monitor were applications that I have created an architecture for and developed using ReactJS framework. Both solutions involved a full end-to-end production flow from development and repository updates through code reviews, tests, build process and continuous integration up to continuous deployments (using Webpack, Github, Heroku, CircleCI and Docker).'
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-ft-contentcomparator',
    image: 'ft-contentcomparator-1.jpg',
    title: 'FT Internal Tool: Content Comparator',
    tags: [
      'Angular2',
      'NodeJS',
      'HTML5',
      'CSS3',
      'Javascript',
      'Webpack',
      'CircleCI',
      'Heroku',
      'Docker'
    ],
    screenshots: [
      {
        img: 'ft-contentcomparator-1.png'
      },
      {
        img: 'ft-contentcomparator-2.png'
      },
      {
        img: 'ft-contentcomparator-3.png'
      }
    ],
    when: 'May 2016 – January 2017',
    client: 'Financial Times',
    description: [
      "During my time at Financial Times, the back-end team was working very hard on a very complex process of transition from an old to a new version of their massive content API. On a daily basis the IT support team was receiving queries about differences in an availability of articles, images or other content assets. To better support those queries, I've been asked to create an internal tool that would be able to request those assets from different sources to quickly compare the results.",
      'That was a high-level requirement of Content Comparator - an application that I had a privilege to fully design from technical perspective and develop using Angular2 framework. The solution that emerged involved a full end-to-end production flow from development and repository updates through code reviews, tests, build process and continuous integration up to continuous deployments (using Webpack, Github, Heroku, CircleCI and Docker).'
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-cdbnext',
    image: 'dt_cdbnext_big.jpg',
    title: 'CDB Next for Deutsche Telekom',
    tags: [
      'AngularJS',
      'NodeJS',
      'HTML5',
      'CSS3',
      'Grunt',
      'esLint',
      'Karma',
      'Mocha'
    ],
    screenshots: [
      {
        img: 'dt_cdbnext_big.jpg'
      },
      {
        img: 'dt_styleguide.png'
      },
      {
        img: 'dt_desktop_version.png'
      }
    ],
    when: 'November 2014 - April 2016',
    client: 'Deutsche Telekom UK',
    description: [
      'As a Lead Front End developer I had a chance to create a FE architecture and to be involved in creation of a new version of Deutsche Telekom flagship online product: the CDB portal that aggregates data from several T-Online services.',
      'During my time at Deutsche Telekom UK I was introducing and managing process of code quality management within the team (coding standards, design patterns, esLint, Karma / Mocha unit tests, Protractor E2E tests)',
      "To make the development flow complete I have designed and implemented a Grunt task runner based solution to deliver a full release process from developer's local environment, through gated commits to repository and CI environment up to UAT/Prod environments."
    ]
  },
  {
    type: 'mobile_app',
    id: 'pf-popup-crfcapp',
    image: 'ls_crfcapp_big.jpg',
    title: 'Football Club Assistant',
    tags: ['AngularJS', 'NodeJS', 'Cordova', 'HTML5', 'CSS3', 'Grunt'],
    screenshots: [
      {
        img: 'ls_crfcapp_fixtures.png'
      },
      {
        img: 'ls_crfcapp_add_game.png'
      },
      {
        img: 'ls_crfcapp_big.jpg'
      }
    ],
    when: 'October 2015',
    client: 'Cassiobury Rangers FC',
    description: [
      'A mobile app for CRFC managers that allows to manage players, teams, opponents and fixtures',
      ' Since it has been adopted by the Cassiobury Rangers FC, the club managers are able to much easier organise games for their players (which previously was happening mostly using Word documents and Excel spreadsheets or simply a piece of paper).',
      'Integrated with shared database, the application allows also to update the game result straight from the pitch, just after the final whistle - and the result is being published directly at the CRFC website.'
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-crfcwebsite',
    image: 'ls_crfc_big.png',
    title: 'A brand new website for CRFC community',
    tags: ['AngularJS', 'ExpressJS', 'HTML5', 'CSS3', 'Wordpress-API'],
    screenshots: [
      {
        img: 'ls_crfc_big.png'
      }
    ],
    when: 'September 2015',
    client: 'Cassiobury Rangers FC',
    description: [
      'A brand new website for CRFC players, parents and managers. Cassiobury Rangers Football Club is an FA Charter Standard Club with a Headquarters at Parmiters School in Watford.',
      'The website is an AngularJS app on the front-end side with classic Wordpress and MySQL database for the content administration. The data is being fetched to the user interface via Wordpress-API.'
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-gabinet',
    image: 'ls_gabinet_big.png',
    title: 'Watford Speech Therapists for Polish children - website',
    tags: ['AngularJS', 'ExpressJS', 'HTML5', 'CSS3', 'Wordpress-API'],
    screenshots: [
      {
        img: 'ls_gabinet_big.png'
      }
    ],
    when: 'September 2015',
    client: "Gabinet Logopedyczny 'Gaduła' w Watford",
    description: [
      'A brand new website for a Speech Therapists for Polish Children in Watford.',
      'The website is an AngularJS app on the front-end side with classic Wordpress and MySQL database for the content administration. The data is being fetched to the user interface via Wordpress-API.'
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-dtbrunel',
    image: 'dt_brunel_big.png',
    title: 'Data allowance app for Deutsche Telekom UK (via EE) customers',
    tags: ['Javascript', 'CSS3', 'HTML'],
    screenshots: [
      {
        img: 'dt_brunel_big.png'
      }
    ],
    when: 'November 2014 - April 2016',
    client: 'Deutsche Telekom UK',
    description: 'Data allowance app for Deutsche Telekom UK (via EE) customers'
  },
  {
    type: 'web_app',
    id: 'pf-popup-tesco',
    image: 'tesco_big.jpg',
    title: 'High traffic e-commerce platform',
    tags: [
      'Javascript',
      'HTML',
      'CSS3',
      'jQuery',
      'Backbone',
      'Grunt',
      'jsLint'
    ],
    screenshots: [
      {
        img: 'tesco_big.jpg'
      }
    ],
    when: 'April 2014 - November 2014',
    client: 'Tesco Technologies',
    description: [
      "At the time I was working at GMO UI Buildkit team and was focusing on responsive and performance solutions for high profile, high traffic eCommerce platform, which is one of Tesco's flagship products: the Tesco Direct.",
      ' After taking over a role of Javascript Team Lead I had a chance to introduce and manage process of code quality improvements within the team (coding standards, design patterns, jsLint, Jasmine unit tests, TDD).',
      "Was also involved in architecture design and development of general front-end solutions for Tesco kiosks experience. At the time I've created a custom MV* API solution based on BackboneJS – built from scratch and tailored for Tesco eCommerce platform specifics."
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-skypeinoutlook',
    image: 'ms_skypeinoutlook_1024.jpg',
    title: 'Skype experience at Outlook.com',
    tags: [
      'HTML5',
      'CSS2/CSS3',
      'Javascript',
      'jQuery',
      'AJAX',
      'JSON/JSONP',
      'HTTP/HTTPS',
      'REST APIs'
    ],
    screenshots: [
      {
        img: 'ms_skypeinoutlook_big.jpg'
      },
      {
        img: 'ms_skypeinoutlook_big2.jpg'
      },
      {
        img: 'ms_skypeinoutlook_big3.jpg'
      },
      {
        img: 'ms_skypeinoutlook_big4.jpg'
      },
      {
        img: 'ms_skypeinoutlook_big5.jpg'
      },
      {
        img: 'ms_skypeinoutlook_big6.jpg'
      }
    ],
    when: 'April 2012 - April 2014',
    client: 'Microsoft / Skype',
    description: [
      'Enabling Skype video calling experience on Outlook.com',
      "It was the biggest project I was involved in during the awesome time I spent at Skype company. We were building a web app that allowed Skype users to communicate between different web platforms. After Skype acquisition by Microsoft, there were several ideas about how to enable Skype experience in Microsoft's flagship products. One of them at the time was Outlook.com.",
      "The Skype web library we'd created did allow Microsoft email users to establish video calls between each other as well as between them and Skype users. Due to an additional possibility of linking accounts easily, the Skype users network has grown up rapidly."
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-skypeinbing',
    image: 'ms_skypeinbing_1024.jpg',
    title: 'Skype experience at Bing.com',
    tags: ['HTML5', 'CSS3', 'Javascript', 'RequireJS', 'Jasmine'],
    screenshots: [
      {
        img: 'ms_skypeinbing_big.jpg'
      }
    ],
    when: 'April 2012 - April 2014',
    client: 'Microsoft / Skype',
    description: [
      'Enabling Skype video calling experience on Bing.com search results.',
      ' As a member of Skype Web Experience team I was working on moving video calling from the OS application to the web. One of the first projects where we were able to see our SkyJS library in action was Skype Web Experience for Bing.com. At the search results page, the search engine users have been given a chance to immediately establish a connection with those who they were looking for - if there was a Skype id or a phone number in their contact details.'
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-fa_wembleystadiumstore',
    image: 'fa_wembleystadiumstore_1024.jpg',
    title: 'Wembley Stadium Online Store',
    tags: [
      'HTML5',
      'XHTML1.0',
      'CSS',
      'CSS2',
      'CSS3',
      'Javascript',
      'jQuery',
      'AJAX',
      'XML+XSLT'
    ],
    screenshots: [
      {
        img: 'fa_wembleystadiumstore_big.jpg'
      }
    ],
    when: 'December 2010 - April 2012',
    client: 'The FA',
    description: [
      'Once the wembleystadium.com website has been successfully rebuilt according to newest web standards and accessibility rules, I have been asked to prepare designs for subpages that have not been prepared during the main development process.',
      'The task was actually quite challenging because I had to prepare the project not only according to existing website design, but to combine expectations of Marketing Department with the e-shop back-end engine from the third-party supplier.',
      'After many years of successfull services provided by traditional Wembley store, now the time has come to conquer the e-market...'
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-fa_wsl',
    image: 'fa_wsl_big.jpg',
    title: 'The FA Women Super League website',
    tags: [
      'HTML5',
      'XHTML1.0',
      'CSS',
      'CSS2',
      'CSS3',
      'Javascript',
      'jQuery',
      'AJAX',
      'XML+XSLT'
    ],
    screenshots: [
      {
        img: 'fa_wsl_big.jpg'
      }
    ],
    when: 'December 2010 - April 2012',
    client: 'The FA',
    description: [
      'On this project I have been working from the very beginning - at the time, when Women Super League was about to be born - so the pressure was significant...',
      'The FA WSL, the Women Super League, was at that time the brand new semi-professional summer football league. My task was to build not only the fawsl.com as the main league WSL website but also eight clubs related instances of the one design.',
      'All of them are are working on the same back-end engine, they look similar, but they are different according to each club brand and special needs.'
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-fa_cup',
    image: 'fa_cup_1024.jpg',
    title: 'The FA Cup website',
    tags: [
      'HTML5',
      'XHTML1.0',
      'CSS',
      'CSS2',
      'CSS3',
      'Javascript',
      'jQuery',
      'AJAX',
      'XML+XSLT'
    ],
    screenshots: [
      {
        img: 'fa_cup_big.jpg'
      }
    ],
    when: 'December 2010 - April 2012',
    client: 'The FA',
    description: [
      'The time has come - the very new The FA Cup website developed with exceptional care of accessibility rules and newest web standards has been released...',
      "The official website for The FA Cup and FA competitions with match highlights, fixtures, results, draws and more. A really huge and complicated project - this website has been built on flexible UI architecture as part of the bigger TheFA.com portal. Next websites: TheFA.com, Women's Super League and big number of County Websites are on their way - all based on the same front end structure."
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-fa_coachesclub',
    image: 'fa_coachesclub_big.jpg',
    title: "The FA Licensed Coaches' Club",
    tags: ['XHTML1.0', 'CSS2', 'Javascript'],
    screenshots: [
      {
        img: 'fa_coachesclub_big.jpg'
      }
    ],
    when: 'December 2010 - April 2012',
    client: 'The FA',
    description: [
      "The FA Licensed Coaches' Club website provides to FA Licensed football coaches the latest methods, session plans, drills, CPD, videos, news and jobs.",
      'The work on this project gave me a good experience and familiarisation with Orchard - a .NET-based CMS application, used also for the very first time by the company I worked for (The FA). The whole philosophy of building Orchard templates allowed me to create modules &amp; components with front-end focused approach.'
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-vu_amplefuture',
    image: 'vu_amplefuture_big.jpg',
    title: 'Amplefuture.com',
    tags: ['XHTML1.0', 'CSS2', 'Javascript'],
    screenshots: [
      {
        img: 'vu_amplefuture_big.jpg'
      }
    ],
    when: 'September 2010 - December 2010',
    client: 'Amplefuture Ltd.',
    description: [
      'Amplefuture Ltd. provides mobile media broadcast solutions, secure online billing &amp; mobile payment solutions, and develop apps for all mobile platforms including Apple OS, Android, JAVA/J2ME, BREW and Windows Mobile.',
      'My task was to create new corporate website from scratch, based on new design created by graphic designer.'
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-vu_trendstop',
    image: 'vu_trendstop_big.jpg',
    title: 'Trendstop.com',
    tags: ['XHTML1.0', 'CSS2', 'Javascript'],
    screenshots: [
      {
        img: 'vu_trendstop_big.jpg'
      }
    ],
    when: 'September 2010 - December 2010',
    client: 'Trendstop',
    description: [
      'Trendstop.com is one of the most famous trend forecasting brands in the world of fashion professionals.',
      'This website has been built and deployed few years ago and many developers worked on it until today.',
      "My task at the time was to rebuild the existing content as well as to create new subpages and templates. In the meantime I have started to work on tidying the code written previously, where it's possible - as it needed a few SEO and efficiency improvements."
    ]
  },
  {
    type: 'mobile_app',
    id: 'pf-popup-vu_mobile',
    image: 'vu_mobile_big.jpg',
    title: 'UEFA.com Micropayments',
    tags: ['XHTML1.0', 'CSS2', 'Javascript'],
    screenshots: [
      {
        img: 'vu_mobile_big.jpg'
      }
    ],
    when: 'September 2010 - December 2010',
    client: 'UEFA.com / Amplefuture',
    description: [
      "At that time, this task was one of the most exciting in my career so far. For the first time I've had a chance to work on a true mobile-only hybrid application.",
      'One of Amplefuture Ltd. company core business pillars is to provide mobile payment solutions for their business partners. This aplication was for UEFA.com website users.',
      "I've prepared from scratch the new wersion of payment pages front-end code - displayed in a web-view wrappers for Android, iOS and other mobile OS users. Working on this gave me great experience and knowledge about differences between building classic and mobile websites."
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-vu_virtualuniverse',
    image: 'vu_virtualuniverse_1024.jpg',
    title: 'virtual-universe.net',
    tags: ['XHTML1.0', 'CSS2', 'Javascript'],
    screenshots: [
      {
        img: 'vu_virtualuniverse_big.jpg'
      }
    ],
    when: 'September 2010 - December 2010',
    client: 'Virtual Universe Ltd.',
    description: [
      "We have a saying in Poland that 'shoemaker usually goes without his own shoes'. It's because he is so focused on working for other people that has no time to work for themselves...",
      "One of my first tasks when I've started to work for Virtual Universe Ltd. company was to finish new wersion of their website, which has been designed some time ago, but never finished.",
      "For the Virtual-Universe.net website I've prepared the front-end code compatible to a framework they use in all their projects (it's framework witten by Virtual Universe Ltd. staff in the past). The previous front-end developer has started work on this website before he has left (finished about 25%) - my task was to reorganize and finish it."
    ]
  },
  {
    type: 'web_app',
    id: 'pf-popup-fitbug',
    image: 'fitbug_1024.jpg',
    title: 'Fitbug.com',
    tags: ['XHTML1.0', 'HTML4', 'CSS2', 'Javascript'],
    screenshots: [
      {
        img: 'fitbug_big.jpg'
      }
    ],
    when: 'December 2006 - November 2008',
    client: 'Fitbug Ltd.',
    description: [
      "At the beginning of 2008 the management of Fitbug Ltd. made a strategic decision to create a completely new design of company's most crucial asset - the website.",
      'In 2006 I have come to London to expand my front-end development skills and knowledge. I have started to work for company which is leader of healthy lifestyle / fitness market in UK.',
      "These days in Fitbug I've worked on rebuilding their website's front-end code from version 2 to version 3, which was necessary because of their market success and services enlargement. I was really big job, which gave me experience I haven't got before - especially in cross-browser non-compatibility, css tricks and building the xhtml code in accordance to W3C standards.",
      "At the beginning of 2008 the management of Fitbug Ltd. has decided to create completely new design of company's website. The back-end code should stay the same, but the front-end code had to be built from the beginning - this meant really exciting challenge and a lot of work for me... And this task has been accomplished with success as well - the actual Fitbug website www.fitbug.com has been built on front-end side by myself."
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-bck',
    image: 'gs_bck_1024.jpg',
    title: "Bielsko's Centre of Culture",
    tags: ['XHTML1.0', 'CSS2', 'Smarty', 'PHP5', 'MySQL'],
    screenshots: [
      {
        img: 'gs_bck_big.jpg'
      }
    ],
    when: 'November 2007 - August 2010',
    client: 'BCK',
    description: [
      "A high traffic portal for the biggest cultural events institution in Bielsko-Biala, Poland: Bielsko's Culture Centre, House of Music.",
      "This site has been built using XHTML1.0, CSS2, Smarty library with dedicated administration panel (PHP5+MySQL) and it enables to add texts, photo galleries and to manage a catalogue of products. Doesn't matter what content site administrator will add - it will always look the same and the site won't break."
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-biznesregion',
    image: 'gs_biznesregion_1024.jpg',
    title: 'Biznesregion',
    tags: ['XHTML1.0', 'CSS2', 'Smarty', 'PHP5', 'MySQL'],
    screenshots: [
      {
        img: 'gs_biznesregion_big.jpg'
      }
    ],
    when: 'November 2007 - August 2010',
    client: 'Biznesregion',
    description: [
      'Business news oriented e-magazine adjusted for business news related to Silesian Area, Poland.',
      "This site has been built using XHTML1.0, CSS2, Smarty library with dedicated administration panel (PHP5+MySQL) and it enables to add texts, polls, photo galleries, sounds(mp3) and movies (flv). Doesn't matter what content site administrator will add - it will always look the same and the site won't break."
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-futuresport',
    image: 'gs_futuresport_1024.jpg',
    title: 'Future Sport',
    tags: ['XHTML1.0', 'CSS2', 'Smarty', 'PHP5', 'MySQL'],
    screenshots: [
      {
        img: 'gs_futuresport_big.jpg'
      }
    ],
    when: 'November 2007 - August 2010',
    client: 'Futuresport sp. z o.o.',
    description: [
      'This site was created for sport clothing online retailer. Their shops sell original products of Nike and Adidas via outposts located in whole Silesian area, Poland.',
      "It has been built using XHTML1.0, CSS2, Smarty library with dedicated administration panel (PHP5+MySQL) and it enables to add texts, photo galleries and to manage a catalogue of products. Doesn't matter what content site administrator will add - it will always look the same and the site won't break."
    ]
  },
  {
    type: 'website',
    id: 'pf-popup-szpital',
    image: 'gs_szpital_1024.jpg',
    title: 'Bielsko-Biala General Hospital',
    tags: ['XHTML1.0', 'CSS2', 'Smarty', 'PHP5', 'MySQL'],
    screenshots: [
      {
        img: 'gs_szpital_big.jpg'
      }
    ],
    when: 'November 2007 - August 2010',
    client: 'Beskidzkie Centrum Onkologii / Szpital Miejski',
    description: [
      'It was a website created from scratch for one of the biggest and oldest health service institution in Bielsko-Biala, Poland.',
      "It has been built using XHTML1.0, CSS2, Smarty library with dedicated simple administration panel (PHP5+MySQL) and it enables to add texts, photos and photo galleries. It contains system which allows to publish categorised news and announcements dedicated to any of hospital wards. Doesn't matter what content site administrator will add - it will always look the same and the site won't broke."
    ]
  }
];

export const Portfolio = () => {
  return (
    <div className="crt-paper clearfix">
      <div className="crt-paper-cont paper-padd clear-mrg">
        <section className="section padd-box">
          <h2 className="title-lg text-upper padd-box">portfolio</h2>

          <div className="pf-wrap">
            <div className="pf-filter padd-box">
              <button data-filter="*">all</button>
              <button data-filter=".web_app">web apps</button>
              <button data-filter=".mobile_app">mobile apps</button>
              <button data-filter=".website">websites</button>
            </div>

            <div className="pf-grid">
              <div className="pf-grid-sizer"></div>

              {!!projects &&
                !!projects.length &&
                projects.map((project, index) => (
                  <Project key={index} project={project} />
                ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
