import * as React from 'react';
import classnames from 'classnames';
import DefaultLayout from '../layouts/Default';
import { About } from '../modules/About';
import { Contact } from '../modules/Contact';
import { Experience } from '../modules/Experience';
import { Portfolio } from '../modules/Portfolio';
import { References } from '../modules/References';
import styles from './homepage.module.scss';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

export default (props: IndexPageProps) => {
  return (
    <DefaultLayout>
      <div id="about" className="crt-paper-layers crt-animate">
        <About />
      </div>

      <div id="experience" className="crt-paper-layers crt-animate">
        <Experience />
      </div>

      <div id="portfolio" className="crt-paper-layers crt-animate">
        <Portfolio />
      </div>

      <div id="references" className="crt-paper-layers crt-animate">
        <References />
      </div>

      <div id="contact" className="crt-paper-layers crt-animate">
        <Contact />
      </div>
    </DefaultLayout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
