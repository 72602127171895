export const references = [
  {
    avatar: 'levent-kucukgorkey.jpg',
    name: 'Levent Kucukgorkey',
    date: '11 Mar 2020',
    company: 'Deutsche Bank',
    title: 'Senior Software Engineer',
    content:
      'I met with Tomasz when he joined Deutsche Bank. We worked together on an efficiency initiative and migrated thousands of unit tests to Jest. From the start he was very vigilant and agile. Quickly took many tasks over and started driving the things. Great teammate and great person.'
  },
  {
    avatar: 'dheeraj-sharma.jpg',
    name: 'Dheeraj Sharma',
    date: '27 Jan 2020',
    company: 'Deutsche Bank',
    title: 'Technical Architect',
    content:
      'It was a pleasure having worked with Tomasz. He is a wonderful colleague and a true tech guy with solid work ethics. He is always go to guy for issues at work. Always motivates team and leads from the front. Very positive, inspiring and has an ability to make the work environment very cool. Every team needs such guy. I recommend him.'
  },
  {
    avatar: 'almos-gabor.jpg',
    name: 'Álmos Gábor',
    date: '12 Jul 2019',
    company: 'GfK',
    title: 'Senior Web Engineer',
    content:
      "I had the pleasure of working with Tomasz at GfK for a few months. In fact, he interviewed me for the role, and he came across as a very experienced developer right away. My senses didn't fail me, as it turned out he was one of the best Front-End engineers in the office, and he's also fun to work with. He's highly collaborative and a true team player with a respectable knowledge of programming technologies, principles and processes. I highly recommend him for any agile development team."
  },
  {
    avatar: 'mark-webb.jpg',
    name: 'Mark Webb',
    date: '01 Jul 2019',
    company: 'GfK',
    title: 'Scrum Master',
    content:
      'I had the pleasure of working with Tomasz as the Scrum Master at GfK and I was impressed with his approach and professionalism. He was a huge asset to the team with his extensive knowledge, his positive attitude, attention to detail and helpfulness. I would look forward to the possibility of working with him again in the future.'
  },
  {
    avatar: 'david-sanders.jpg',
    name: 'David Sanders',
    date: '04 Jan 2019',
    company: 'Telegraph Media Group',
    title: 'Head of Architecture',
    content:
      'Tomasz is an exemplary Software Engineer. Confident and professional at all times, he delivers quality software in a timely manner. He has proven he can communicate well with stakeholders and integrate into a fast paced agile team, taking the lead where necessary. Tomasz is a pleasure to work with and I hope we get another opportunity to do so in the future.'
  },
  {
    avatar: 'subhav-gautam.jpg',
    name: 'Subhav Gautam',
    date: '04 Jan 2019',
    company: 'Telegraph Media Group',
    title: 'Software Engineer',
    content:
      'I worked closely with Tomasz for 4 months during his time within the Newsroom team. It was a pleasure working with him as he has been a great mentor. He is an excellent communicator and reliable when it comes to delivering features with outstanding quality. I would recommend Tomasz to anyone who is looking for a hard-working, intelligent and friendly addition to their team.'
  },
  {
    avatar: 'grzegorz-hotala.jpg',
    name: 'Grzegorz Hotała',
    date: '20 Aug 2018',
    company: 'ASOS',
    title: 'Senior Web Engineer',
    content:
      'I had a pleasure of working with Tomasz for several months. I was hugely impressed with his work ethic, focus and determination to get things done, as well as his ability to get up to speed on the project quickly. He has proven a great teammate, a knowledgeable and patient tutor, a true champion of test-driven development and a very likable colleague to boot. I wish Tomasz all the best in his future endeavors.'
  },
  {
    avatar: 'santiago-angulo.jpg',
    name: 'Santiago Angulo',
    date: '10 July 2018',
    company: 'ASOS',
    title: 'Test Automation Engineer',
    content:
      'Tomasz and I we both worked at ASOS for several months, and some of that time we worked in the same team. From a QA perspective I can not value his code much, since my coding ability is well below his, however I can value the quality of his work, and his communication skills and attitude in our day to day work and I consider them excellent. Whenever I had any doubts, any issues he would respond openly and solve them very quickly. I would say it was a real pleasure working that time with Tomasz, the quality of work produced but also the feeling of working with a great person that values his work and takes care of the team needs. A truly valuable team member that I hope to work with in the near future.'
  },
  {
    avatar: 'matt-koss.jpg',
    name: 'Matt Koss',
    date: '03 Mar 2018',
    company: 'Aimia Inc',
    title: 'Technical Architect',
    content:
      "It's been a great pleasure having Tomasz on our team. Highly professional, independent and driven with strong skills all around. Always very courteous and polite, yet not afraid to offer an opinion when needed. I can highly recommend Tomasz as a reliable senior asset for any team."
  },
  {
    avatar: 'dominic-reeves.jpg',
    name: 'Dominic Reeves',
    date: '08 Nov 2017',
    company: 'Aimia Inc',
    title: 'Global Director of UX',
    content:
      "I was lucky to have met Tomasz while he was leading development with the FT, and immediately sought to have him join our growing team at Aimia. Tomasz's work with Aimia has been exceptional throughout his tenure, and his contribution as a thought-leader, technical adviser, and team-member was equally qualitative and consistent. It'll be my great fortune to cross-paths with him again!"
  },
  {
    avatar: 'greg-kedzierski.jpg',
    name: 'Greg Kedzierski',
    date: '07 Nov 2017',
    company: 'Aimia Inc',
    title: 'Front-End Lead',
    content:
      "Tomasz is one of the most talented and hard-working developers I've ever had a pleasure to work with. His knowledge and experience in front-end technologies is impressive, as is his ability to solve any problems, as well as an ability to adapt to new environments. Despite being reassigned to different teams working on different parts of the application, he was up and running at full speed in no time. In addition to that, he's a real team player and a great colleague to everyone he works with. I'd definitely recommend Tomasz and hope I'll have a pleasure of working with him again in the future."
  },
  {
    avatar: 'georgiana-bogdan.jpg',
    name: 'Georgiana Bogdan',
    date: '07 Nov 2017',
    company: 'Aimia Inc',
    title: 'Product Owner',
    content:
      "It was great working in the same team as Tomasz at Aimia. He's a quick learner - he picked up very fast all the intricacies of the BI tool we were working on and managed to drive development. He's great at communicating issues and explaining the pros and cons of different implementation scenarios. He's also really comfortable working in a team and collaborating with everybody working on the product (design, QA, business stakeholders etc.). And he has such a positive attitude, in and outside work. Our lunches were so fun with him around! Hope I get a chance to work with him in the future."
  },
  {
    avatar: 'edwin-paul.jpg',
    name: 'Edwin Paul',
    date: '06 Nov 2017',
    company: 'Aimia Inc',
    title: 'QA Engineer',
    content:
      'I am pleased to write reference for Tomasz Libich who I enjoyed working with. About his performance, more than his high quality of code, the way he prepare for the story in planning session is the most I wondered. I always be surprised the way he solve the problem. Even when he is staffed with UI dev work, he always extend his support to the team members in term of sharing knowledge.'
  },
  {
    avatar: 'edward-knowles.jpg',
    name: 'Edward Knowles',
    date: '03 Nov 2017',
    company: 'Aimia Inc',
    title: 'Front-End Developer',
    content:
      'Tomasz is a highly experienced developer, he brought a wealth of knowledge of software engineering and agile practices to the team and is great to work with.'
  },
  {
    avatar: 'deepa-radhakrishnan.jpg',
    name: 'Deepa Radhakrishnan',
    date: '10 Oct 2017',
    company: 'Aimia Inc',
    title: 'Product Owner',
    content:
      'Tomasz was in my team as Senior UI Developer. He is the best to work with and always fun to be around him. He connects and collaborate the team very well. He did an exceptional job on the recent projects. He is very productive and focused on his work. Self motivated and intelligent team player. His turn around time on the bug fixes are super quick and I always amazed at him. I would highly recommend him.'
  },
  {
    avatar: 'jodie-lord.jpg',
    name: 'Jodie Lord',
    date: '04 Oct 2017',
    company: 'Aimia Inc',
    title: 'Product Owner',
    content:
      'Tomasz proved to be diligent, creative, and team orientated throughout my time working with him at AIMIA. progressed through the development of various areas of the application with ease and always bought valid and creative ideas to the team environment. A joy to work with.'
  },
  {
    avatar: 'neil-latham.jpg',
    name: 'Neil Latham',
    date: '29 Jun 2016',
    company: 'Deutsche Telekom UK',
    title: 'Lead Technical Architect',
    content:
      'Tomasz was a trusted and highly capable front end engineer in our team, and among other projects, lead a significant redesign and implementation of a critical front end component. He used his knowledge of recent technologies, and JS expertise to successfully delivery this SPA. As you would expect from a technical lead, Tomasz covered all aspects of this delivery including design, coding, build, release, testing and interfacing with technical and business colleagues.'
  },
  {
    avatar: 'steve-tyrell.jpg',
    name: 'Steve Tyrell',
    date: '12 April 2016',
    company: 'Deutsche Telekom UK',
    title: 'Development Manager',
    content:
      'I thoroughly enjoyed working with Tomasz in his role as Front End Lead Developer. He brings with him a wealth of knowledge in his field which he is happy to share, is extremely professional with clear focus on delivering business value and he is a motivated team player with a can-do attitude.'
  },
  {
    avatar: 'jitesh-dineshandra.jpg',
    name: 'Jitesh Dineschandra',
    date: '26 May 2016',
    company: 'Tesco Technologies',
    title: 'Development Manager',
    content:
      'I had the rare opportunity to hire Tomasz into my team whilst I was the Development Manager at Tesco.com. The first thing you will immediately notice about Tomasz is his approachable character, his magnetic personality and humour not to mention his technical JavaScript sills. Tomasz is one of the best tech leads I have ever worked with hands down. He is technically accurate, he is an expert in JavaScript including Backbone.js and many other JavaScript frameworks. I was also hugely impressed with his ability to lead a team, getting the work done and his work ethic is admirable. He is a team player, fun to work with and helped Tesco.com deliver projects from greenfield stages through to production. Guys if Tomasz CV lands on your desk, hire him! As you have gathered I highly recommend Tomasz and would work with Tomasz again in the future.'
  },
  {
    avatar: 'laurent-mignot.jpg',
    name: 'Laurent Mignot',
    date: '11 Feb 2015',
    company: 'Deutsche Telekom UK',
    title: 'Front-End Lead',
    content:
      "I had the chance to work with Tomasz for about 4 months before moving on to a career change as a contractor. I thoroughly enjoyed working with Tomasz; he was very enthusiastic, always cheerful and professional, and jumped straight into working with me to architect and design our new product, replacing me as team lead on my departure. Tomasz is a very experienced engineer and was a great sounding board for ideas, providing valuable feedback throughout our time working together. I'd highly recommend  Tomasz as a member of any team and look forward to opportunities to work with him again in the future."
  },
  {
    avatar: 'alex-vickers.jpg',
    name: 'Alex Vickers',
    date: '10 Dec 2014',
    company: 'Tesco Technologies',
    title: 'Scrum Master',
    content:
      'Having worked closely with Tomasz for over six months, I have consistently been impressed with the high level of quality he has applied to his work. Better still, he has enthusiastically shared this knowledge with the rest of the team and raised overall development standards as a result. Tomasz is a prized asset to any development team.'
  },
  {
    avatar: 'joe-terell.jpg',
    name: 'Joe Terell',
    date: '25 Nov 2014',
    company: 'Tesco Technologies',
    title: 'Senior Software Engineer',
    content:
      'Tomasz is an excellent developer who brought both an experienced skill set and a fantastic attitude into the Tesco development team. Tomasz joined Tesco as a Senior JavaScript developer and over eight months introduced efficient new ways of working that increased productivity, output and quality across the development process. Tomasz is a great developer to work alongside and he would be an asset to any development team. He comes highly recommended.'
  },
  {
    avatar: 'abdellah-elamiri.jpg',
    name: 'Abdellah Elamiri',
    date: '08 Mar 2014',
    company: 'Microsoft',
    title: 'Development Manager',
    content:
      'I worked with Tomasz for about a few months as a peer before I took over as a team lead and became his manager. Tomasz is great team member to have. He is very professional and well liked by all his peers. He is very pragmatic and willing to accept that there is a better way of doing a task regardless of personal ideology. As a front end engineer, he drove localization efforts, as well as several refactoring efforts to make our code-base more maintainable and testable. He also organized tech talks in the team to tackle common design problems. I very much enjoyed working with Tomasz. He has been an asset in our team. I would not hesitate to recommend him at any time.'
  },
  {
    avatar: 'nick-crabtree.jpg',
    name: 'Nick Crabtree',
    date: '18 Mar 2014',
    company: 'Microsoft',
    title: 'Lead Principal Software Engineer',
    content:
      'Tomasz has strong technical skills, a firm grasp of software architecture principles and a solid ability to deliver. He is a great team player with an appreciably well-balanced combination of motivation, dedication, pragmatism and camaraderie. Tomasz would be an asset to any team he joined and I would not hesitate to recommend him.'
  },
  {
    avatar: 'petr-komarek.jpg',
    name: 'Petr Komarek',
    date: '17 Mar 2014',
    company: 'Microsoft',
    title: 'Senior Software Engineer',
    content:
      "When it comes to work, Tomasz was always rigorous and making sure to deliver valuable features. Tomasz was also a great colleague who was here not only to educate others, but also to listen to them and taking part in precious discussions. And I'm not talking about the fun I had with him!"
  },
  {
    avatar: 'gary-collins.jpg',
    name: 'Gary Collins',
    date: '21 Mar 2014',
    company: 'Microsoft',
    title: 'Software Engineer II',
    content:
      "Tomasz was awesome to work with, always willing to give help when you needed it and loved to dive deep into difficult problems, pushing for the right solution, not the easiest! I've learned a lot from Tomasz, his knowledge of JavaScript and agile methodologies was invaluable to me when I joined the team and we were all sad to see him go. Highly recommended!"
  },
  {
    avatar: 'sandor-torok.jpg',
    name: 'Sándor Török',
    date: '25 Nov 2016',
    company: 'Financial Times',
    title: 'Software Engineer',
    content:
      'I worked with Tomasz for a short period, and I was really pleased with his work attitude. He was working as a frontend developer and me as a backend developer on an application in the world of news publishing. The frontend application on which he was working on follows the best practices in NodeJS; during our cooperation he was always helpful and provided information to better understand the whole system architecture. He also gave meticulous updates on the project state and signalled any arising problems. It was a pleasure to work with him.'
  },
  {
    avatar: 'mindaugas-vaiciulis.jpg',
    name: 'Mindaugas Vaiciulis',
    date: '23 Apr 2012',
    company: 'The FA',
    title: 'Senior UI Developer',
    content:
      'Tomasz was one of the best UI developers who I worked with, very intelligent, smart, quick learner and always willing to help. I would recommend him to anyone without a shadow of a doubt. Keep up good work Tom!'
  }
];
