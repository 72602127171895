import * as React from 'react';
import { Client } from './Client';
import { clients } from '../../data/clients';

export const Clients = () => {
  return (
    <section className="section clear-mrg padd-box">
      <h2 className="title-lg text-upper">Clients</h2>
      <div className="padd-box-sm">
        {clients.length && (
          <div className="clients" id="clients-nav">
            {clients.map((client, index) => (
              <Client key={index} client={client} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
