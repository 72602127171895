export const languages: ILanguage[] = [
  {
    title: 'English',
    progress: 9,
    text: 'fluent'
  },
  {
    title: 'Polish',
    progress: 10,
    text: 'native'
  },
  {
    title: 'Spanish',
    progress: 2,
    text: 'beginner'
  }
];
